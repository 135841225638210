<template>
	<div id="js_selfcheck_container" class="headache-type-content">

		<headache-type-check />

		<section class="headache-type-content__description">
			<div class="headache-type-content__description--inner">
				<h2 class="headache-type-content__description--title">こんな頭痛は、すぐに医師の診断を！</h2>
				<p>いつもとは違う激しい頭痛、ろれつが回らないなど言葉に影響がでる、物が二重に見える、発熱を伴った頭痛などの場合は、すぐに専門医に相談しましょう。</p>
			</div>
		</section>

		<div class="headache-type-content__supervisor">
			<h2 class="headache-type-content__supervisor--title">この記事の監修者</h2>
			<div class="headache-type-content__supervisor--content">
				<img src="@/assets/img/headache-type-check/supervisor_icon.png" alt="舟久保恵美先生">
				<div class="headache-type-content__supervisor--content-inner">
					<span>舟久保 恵美 先生</span>
					<p>医学博士／保健師／健康気象アドバイザー／慶應義塾大学医学部神経内科非常勤講師</p>
					<p>名古屋大学環境医学研究所にて、天気と痛みの関係について研究し、医学博士号を取得。<br>日本で唯一の低気圧頭痛を専門にする産業保健師（現在、内田洋行グループ企業の従業員の健康管理を行っている）。</p>
				</div>
			</div>
		</div>

		<!-- アウトロ -->
		<section class="headache-type-content__outro growthhack-f">
			<h2 class="headache-type-content__outro--title"><span>頭痛ーるで頭痛に<br>なりやすい日を知ろう！</span></h2>
			<p class="headache-type-content__outro--text">頭痛ーるは、頭痛の原因となる「気圧の変化」をお知らせします。</p>
			<div class="growthhack-f__caution">
				<p class="growthhack-f__caution--right">低気圧で体調崩すかも。早めに用事を済ませよう！</p>
				<p class="growthhack-f__caution--left">痛みが出る前に薬を準備しておこう！</p>
			</div>
			<div class="growthhack-f__with-zutool">
				<p class="growthhack-f__with-zutool--title">
					with <span class="is-large">頭痛ーる</span>で<br>気圧と上手に付き合って<br>快適な毎日へ♪
				</p>
				<i class="growthhack-f__with-zutool--img sprite_growthhack-f img02" />
			</div>
			<div class="about-rightnow__login">
				<a v-if="isAndroid" href="dcmdam://launch?url=http%3A%2F%2Fapp-manager.docomo.ne.jp%2FSpApps%2FdetailApp%3Fdcmstore_view%3Dnone%26cId%3D10000023012" class="about-rightnow__login--button" id="trackingAutonomicNerves2021Btn06_android">便利なアプリをダウンロード<span>スゴ得ユーザーは使い放題！</span></a>
				<a v-else @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=appDownload`, _backPage: 'top' } })" class="about-rightnow__login--button" id="trackingAutonomicNerves2021Btn06_ios">便利なアプリをダウンロード<span>スゴ得ユーザーは使い放題！</span></a>
			</div>
			<div class="headache-type-content__back">
				<a @click="$router.push({ name: 'Cpsite', query: { id: sugotokuContentsId } })" class="about-rightnow__login--button">TOPにもどる</a>
			</div>
		</section>
		<!-- /アウトロ -->
	</div>
</template>

<script>
// Vuex
import { mapGetters } from 'vuex'

import cmnConst from '@/assets/js/constant.js'
import HeadacheTypeCheck from '@/components/Molecules/HeadacheTypeCheck'

export default {
	name: 'HeadacheTypeContent',
	components: {
		HeadacheTypeCheck
	},
	data () {
		return {
			backendUrl: cmnConst.BACKEND_URL,
			sugotokuContentsId: cmnConst.SUGOTOKU_CONTENTS_ID
		}
	},
	computed: {
		// map Vuex getters
		...mapGetters('common', ['isAndroid'])
	},
	mounted () {
		// コンテンツの高さをemitする処理
		// CHANGED: コンテンツの高さを確保する処理の見直しのためコメントアウト
		// this.$nextTick(() => {
		// 	// TODO: 表示が崩れるため、一旦「setTimeout」で対応
		// 	setTimeout(() => {
		// 		const domContainer = document.getElementById('js_selfcheck_container')
		// 		if (domContainer) this.$emit('containerHeight', domContainer.clientHeight)
		// 	}, 1000)
		// })
	},
	beforeDestroy () {
	},
	methods: {
	}
}
</script>

<style lang="scss">
// 変数定義SASS
@import "@/assets/sass/variable";

.headache-type-content {
	width: 100%;
	&__description {
		width: 93.75%;
		margin: $spacing-20 auto 0;
		&--inner {
			margin-top: $spacing-20;
		}
		&--title {
			font-size: $font-size-16;
			padding: $spacing-10 $spacing-10 $spacing-8;
			background: $primary;
			color: $text-seconday;
			margin-bottom: $spacing-20;
			position: relative;
		}
		&--title::after {
			border: 9px solid transparent;
			border-top: 15px solid $primary;
			content: "";
			left: $spacing-30;
			position: absolute;
			top: 100%;
			box-sizing: border-box;
		}
	}

	&__supervisor {
		width: 93.75%;
		margin: $spacing-20 auto 0;
		padding: $spacing-16 $spacing-20 $spacing-20;
		background: $background-secondary;
		box-sizing: border-box;
		border-radius: 10px;
		font-family: sans-serif;
		font-weight: bold;
		&--title {
			margin: 0;
			border-bottom: 1px solid $line-primary;
			padding-bottom: $spacing-8;
		}
		&--content {
			display: block;
			margin-top: $spacing-8;
			padding: 0;
			align-items: flex-start;
			img {
				margin: 0 auto;
				display: block;
				height: auto;
				width: 108px;
			}
			&-inner {
				margin-left: 0;
				margin-top: $spacing-10;
				span {
					text-align: center;
					display: block;
					font-size: $font-size-16;
					margin-bottom: $spacing-4;
				}
				p {
					font-size: $font-size-14;
					margin-top: $spacing-20;
					line-height: $line-height;
				}
				p+p{
					margin: 0;
				}
			}
		}
	}

	&__outro {
		margin: $spacing-20 auto 0;

		&--title {
			margin: 0;
			color: $primary;
			text-align: center;
			font-size: $font-size-20;

			@media (max-width: (340px)) { font-size: $font-size-18; }

			span {
				position: relative;
				font-weight: bold;

				&::before {
					content: "";
					border: 1px solid $primary;
					position: absolute;
					top: 0;
					left: -3em;
					height: 100%;
					transform: rotate(-30deg);
				}

				&::after {
					content: "";
					border: 1px solid $primary;
					position: absolute;
					top: 0;
					right: -1.4em;
					height: 100%;
					transform: rotate(30deg);
				}
			}
		}

		&--text {
			margin: $spacing-10 0 0 0;
			padding: 0 $spacing-14;
			line-height: $line-height;
		}

		.growthhack-f__caution { margin: $spacing-14 0 0 0; }
		.about-rightnow__login { padding-top: 0; }
	}

	&__back {
		margin: $spacing-20 0 0 0;
		.about-rightnow__login--button { font-weight: normal; }
	}
}
</style>
